






import { Component, Vue } from 'vue-property-decorator'

import EventStudyForm from '@/partials/forms/Events/StudyForm.vue'

@Component({
  components: {
    EventStudyForm
  }
})
export default class EventStudyCreate extends Vue {

}
